import React, { useState } from "react";
import "./Cta.css";
import ConsultationModal from "./ConsultationModal";

export default function Cta({ open, setOpen, handleOpen, handleClose }) {

  const [consult, setConsult] = useState(false)
  return (
    <div class="hero-section">
      <div class="hero-content cta-content">
        <p class="heading">Consult With Us</p>
        <p class="sub-heading">
          <span class="highlight">Ecohodo’s expert team</span> is here to assist
          you with tailored strategies, from emissions reporting to{" "}
          <span class="highlight">regulatory compliance.</span>
        </p>
        <p>
          Book your consultation today to discover how we can help your business
          achieve its sustainability targets with{" "}
          <span class="highlight">precision and transparency</span>. 
        </p>
        <div class="btns cta-btn">
          <button class="btn1" onClick={()=>setConsult(true)}>
            Book A Consultation
          </button>
          <ConsultationModal
            title={true}
            open={consult}
            handleClose={()=>setConsult(false)}
          />
        </div>
      </div>
    </div>
  );
}
