import React from "react";
import "./Projects.css";
import { mapBase } from "../../assets/svgs/mapBase";

export default function Projects() {
  let demoArr = [
    {
      img: require("../../assets/images/01.png"),
      title: "2275 Acre Mangrove Plantation",
      location: "Gujarat, India",
      alt: "01",
    },
    {
      img: require("../../assets/images/02.png"),
      title: "5000 Sustainable agriculture",
      location: "Northeastern states of India",
      alt: "02",
    },
    {
      img: require("../../assets/images/03.png"),
      title: "3000 Acre Bamboo plantation project",
      location: "Maharashtra, India",
      alt: "03",
    },
    {
      img: require("../../assets/images/04.png"),
      title: "50000 Acre regenerative agriculture",
      location: "Maharashtra, India",
      alt: "04",
    },
    {
      img: require("../../assets/images/05.png"),
      title: "Emission reductions by EV",
      location: "India",
      alt: "05",
    },
    {
      img: require("../../assets/images/06.png"),
      title: "High Tech Biochar project",
      location: "Uttarakhand, India",
      alt: "06",
    },
    {
      img: require("../../assets/images/07.png"),
      title: "5000 Acre rubber plantation",
      location: "Madhya Pradesh, India",
      alt: "07",
    },
    {
      img: require("../../assets/images/08.png"),
      title: "365000 ton waste management project",
      location: "Tamil Nadu, India",
      alt: "08",
    },
    {
      img: require("../../assets/images/09.png"),
      title: "21600 Acre Mahogany plantation project",
      location: "Maharashtra, India",
      alt: "09",
    },
  ];
  return (
    <div className="project-section-main" id="OurProjects">
      <div className="project-content">
        <h2>Our Leading Offset Projects</h2>
      
        <div className="project-catalog">
          {demoArr.map((item, indx) => {
            return (
              <div className="project" key={item.title + indx}>
                <div className="project-img df-aic-jcsa">
                  <img src={item.img} alt={item.alt} />
                </div>
                <div className="project-title">
                  <div>{item.title}</div>
                  <p>{item.location}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {mapBase}

    </div>
  );
}
