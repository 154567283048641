import React from "react";
import Hero from "./components/Hero";
import Services from "./components/Services";
import Features from "./components/Features";
import "./Home.css";
import Cta from "./components/Cta";
import Testimonials from "./components/Testimonials";
import Projects from "./components/Projects";
import OurPartners from "./components/OurPartners";
import Blogs from "./components/Blogs";





export default function Home({
  contactUsOpen,
    setContactUsOpen,
    contactOpen,
    contactClose,
    showToast,
}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  
  return (
    <div class="main-style">
      <Hero />
      <Services
        open={open}
        setOpen={setOpen}
        handleOpen={handleOpen}
        handleClose={handleClose}
        showToast={showToast}

        contactUsOpen={contactUsOpen}
        setContactUsOpen={setContactUsOpen}
        contactOpen={contactOpen}
        contactClose={contactClose}
      />
      <Features />
      <OurPartners />
      <Cta
        open={open}
        setOpen={setOpen}
        handleOpen={handleOpen}
        handleClose={handleClose}
        showToast={showToast}
      />
      <Projects />
      {/* <Testimonials /> */}
      {/* <Blogs /> */}
      
    </div>
  );
}
