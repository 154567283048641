/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { TextField, Button, Grid, Box, Typography } from "@mui/material";
import consultation from "../../assets/images/consultationImg.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { POST } from "./Request";
import { toast } from "react-toastify";

export default function ConsultationForm(props) {

  const defaultValues = {
    name: "",
    email: "",
    phone: "",
    companyName: "",
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ ...defaultValues });

  const onSubmit = async (data) => {
    const payload = {
      userName: data["name"],
      userEmail: data["email"],
      userPhone: data["phone"],
      companyName: data["companyName"],
      type: props?.title === true ? "Consultation" : "Demo",
    };

    const res = await POST("consultant-submission", payload);

    if (!res.status) {
      toast.error("Something went wrong!");
      return;
    }

    toast.success("Form submitted successfully!");
    reset(defaultValues);
    props.handleClose();
  };

  const textColor = {
    color: "white",
  };

  const textFieldStyles = {
    "& .MuiInputBase-root": {
      "& .MuiSvgIcon-root": {
        color: "green", // Example color for calendar icon if used
      },
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white", // Default border color
      },
      "&:hover fieldset": {
        borderColor: "white", // Border color on hover
      },
      "&.Mui-focused fieldset": {
        borderColor: "white", // Border color when focused
      },
    },
    "& .MuiInputLabel-root": {
      color: "white", // Label color
    },
    "& .MuiInputBase-input": {
      color: "white", // Input text color
    },
  };

  const [formattedDate, setFormattedDate] = useState("");

  const convertToIST = (date) => {
    const offsetIST = 5.5 * 60 * 60 * 1000; // IST offset from UTC in milliseconds
    const istDate = new Date(date.getTime() + offsetIST);
    return istDate.toISOString().replace("Z", "+05:30");
  };

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      sx={{
        backgroundColor: "rgba(78, 188, 103, 1)",
        maxWidth: "756px",
        margin: "30px auto",
        borderRadius: "45px",
        display: "flex",
        justifyContent: "space-between",
        padding: "0px !important",
        width: {
          xs: "80%",
          md: "100%",
        },
      }}
    >
      {/* Image Section */}
      <Grid
        item
        xs={0}
        md={5}
        sx={{
          padding: "0px !important",
          width: "30%",
          display: {
            xs: "none",
            md: "block",
          },
        }}
      >
        <img
          src={consultation}
          alt="Your Image Here"
          style={{
            width: "80%",
            margin: "auto",
            justifyContent: "start",
          }}
        />
      </Grid>

      {/* Form Section */}
      <Grid
        item
        xs={12}
        md={7}
        sx={{
          width: "70%",
          backgroundColor: "grey",
          borderTopRightRadius: "8%",
          borderBottomRightRadius: "8%",
          borderTopLeftRadius: {
            xs: "8%",
            md: "0",
          },
          borderBottomLeftRadius: {
            xs: "8%",
            md: "0",
          },
        }}
      >
        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            padding: "30px",
            textAlign: "start",
            width: "80%",
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Typography
            variant="h4"
            component="div"
            gutterBottom
            sx={{ ...textColor, fontSize: "28px", fontWeight: "bold" }}
          >
            Book Your Slot
          </Typography>

          {/* name field */}
          <Controller
            name="name"
            control={control}
            rules={{ required: "Name is required" }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Name"
                variant="outlined"
                error={!!errors.name}
                helperText={errors.name ? errors.name.message : ""}
                sx={textFieldStyles}
              />
            )}
          />

          {/* email field */}
          <Controller
            name="email"
            control={control}
            rules={{
              required: "Email is required",
              pattern: {
                value: /^\S+@\S+$/i,
                message: "Invalid email address",
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Email Address"
                variant="outlined"
                error={!!errors.email}
                helperText={errors.email ? errors.email.message : ""}
                sx={textFieldStyles}
              />
            )}
          />

          {/* phone number field */}
          <Controller
            name="phone"
            control={control}
            rules={{
              required: "Phone number is required",
              pattern: { value: /^\d{10}$/, message: "Invalid phone number" },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Phone Number"
                variant="outlined"
                error={!!errors.phone}
                helperText={errors.phone ? errors.phone.message : ""}
                sx={textFieldStyles}
              />
            )}
          />

          {/* date time field */}
          <Controller
            name="dateTime"
            control={control}
            defaultValue={null}
            rules={{ required: "Date and time are required" }}
            render={({ field }) => (
              <DatePicker
                {...field}
                selected={field.value} // Bind the selected date to the field value
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                dateFormat="MMMM d, yyyy h:mm aa"
                customInput={
                  <TextField
                    fullWidth
                    label="MM/DD/YYYY"
                    error={!!errors.dateTime}
                    helperText={errors.dateTime ? errors.dateTime.message : ""}
                    sx={{ ...textFieldStyles, width: "100%" }}
                    value={field.value ? field.value.toLocaleString() : ""} // Display the value
                  />
                }
                onChange={(date) => {
                  const formatted = convertToIST(date);
                  // console.log("formated", formatted);
                  setFormattedDate(formatted);
                  field.onChange(date);
                }}
              />
            )}
          />

          {/* company name field */}
          <Controller
            name="companyName"
            control={control}
            rules={{ required: "Company Name is required" }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Company Name"
                variant="outlined"
                error={!!errors.name}
                helperText={errors.name ? errors.name.message : ""}
                sx={textFieldStyles}
              />
            )}
          />

          {/* button field */}
          <Button
            type="submit"
            variant="contained"
            sx={{
              backgroundColor: "rgba(38, 158, 64, 1)",
              color: "white",
              textTransform: "none",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "rgba(38, 158, 64, 0.6)",
              },
            }}
          >
            Confirm
          </Button>

        </Box>
      </Grid>

    </Grid>
  );
}
