import React from "react";
import Carousel from "react-material-ui-carousel";
import { Paper, Grid } from "@mui/material";
import CompanyOne from "../../assets/images/partner-img1.png";
import CompanyTwo from "../../assets/images/partner-img2.png";
import CompanyThree from "../../assets/images/partner-img3.png";
import CompanyFour from "../../assets/images/partner-img4.png";
import CompanyFive from "../../assets/images/partner-img5.png";
import CompanySix from "../../assets/images/partner-img6.png";
import CompanySeven from "../../assets/images/partner-img7.png";
import CompanyEight from "../../assets/images/partner-img8.png";


const partnerImages = [
    [
        { name: "radisson_blu", img: CompanyOne },
        { name: "next_grow_green", img: CompanyTwo },
        { name: "pugdundee_safari", img: CompanyThree },
        { name: "Image 4", img: CompanyFour }
    ],
    [
        { name: "Image 5", img: CompanyFive },
        { name: "Image 6", img: CompanySix },
        { name: "Image 7", img: CompanySeven },
        { name: "Image 8", img: CompanyEight }
    ],
];

function PartnerCarousel() {
  return (
    <Carousel>
            {partnerImages.map((itemGroup, index) => (
                <Paper key={index} elevation={2} sx={{backgroundColor: "transparent"}}>
                    <Grid container spacing={2}>
                        {itemGroup.map((item, idx) => (
                            <Grid item xs={3} key={idx}>
                                <img src={item.img} alt={item.name} style={{ width: '80%', height: 'auto' }} />
                            </Grid>
                        ))}
                    </Grid>
                </Paper>
            ))}
    </Carousel>
  );
}

export default PartnerCarousel;
