import * as React from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import ConsultationForm from "./ConsultationForm";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  marginTop: "300px",
};

export default function ConsultationModal(props) {
  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"

      >
        <ConsultationForm title={props.title} handleClose={props.handleClose} />
      </Modal>
    </div>
  );
}
