let baseUrl = "https://dev-auth.ecohodo.ai/api/v1/"

const validateResponse = (e) => {
  if (e?.["status"] === false || e?.["error"]) {
    return {
      status: false,
      message: e?.message || "Something went wrong",
    };
  } else {
    if (e?.["status"] === undefined && e?.["success"] === undefined) {
      return {
        status: true,
        data: e,
      };
    } else {
      return e;
    }
  }
};

const prepareHeaders = (config) => {
  const token = localStorage.getItem("auth");
  const headers = {};
  if (!config?.["formData"]) {
    headers["Content-Type"] = "application/json";
  }

  headers["Accept"] = "application/json";
  if (token) headers["Authorization"] = `Bearer ${token}`;
  return headers;
};

export const POST = async (endpoint, data, config) => {
  let url = baseUrl + endpoint;
  if (config?.fullUrl) {
    url = endpoint;
  }
  let body = data;
  const headers = { ...prepareHeaders(config) };
  if (!config?.rawBody && !config?.formData) {
    body = JSON.stringify({
      ...data,
    });
  }
  return fetch(url, {
    method: "POST",
    headers: headers,
    body: body,
  })
    .then((res) => res.json())
    .then((e) => {
      const response = validateResponse(e);
      return response;
    })
    .catch((e) => {
      return { status: false, message: e?.message || "Something went wrong" };
    });
};









