import React from "react";

import './BlogListing.css'
import BlogBox from "./BlogBox";
import Newsletter from "../Newsletter";


export default function BlogListing() {
  return (
    <div className="main-style">
    
      <div className="hero-section">
        <div className="hero-content">
            <h1>Our Blogs</h1>
            <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
            faucibus mi ac elit sagittis mattis. Interdum et malesuada fames ac
            ante ipsum primis in faucibus.
            </p>
        </div>
      </div>
      <BlogBox />
      {/* <Newsletter /> */}
    
    </div>
  );
}
