/* eslint-disable react/jsx-no-target-blank */
import React from "react";

import cookiePolicy from "./cookiePolicy.json";
import "./policy.css";


export default function CookiePolicy() {
  return (
    <div className="main-style">
     
      <div className="policyTitle">
        <h1><span className="highlight">Cookie Policy</span> for Ecohodo Technologies Pvt. Ltd.</h1>
      </div>
      <div className="policyContent">
        <p>
          This Cookie Policy explains how <b>Ecohodo Technologies Pvt Ltd</b>
          (“Ecohodo,” “we,” “us,” or “our”) uses cookies and similar
          technologies on our website ecohodo.ai (the "Website"), as well as in
          connection with our <b>Carbon Credits Marketplace, Consulting Services</b>,
          and SaaS products <b>GHGsync</b> and <b>TrueMRV </b>(collectively referred to as the
          "Services"). This policy provides details about the types of cookies
          we use, the information we collect using cookies, and how you can
          manage your cookie preferences. By using our Website or Services, you
          agree to the use of cookies as described in this policy. If you do not
          agree to our use of cookies, please disable them following the
          instructions in this Cookie Policy or discontinue using our Website.
        </p>
        {cookiePolicy.map((section) => (
          <div key={section.id}>
            <h2>
              {section.id}. {section.title}
            </h2>
            <p dangerouslySetInnerHTML={{ __html: section.content }} />
            {section.subContent &&
              section.subContent.map((sub, index) => (
                <div key={index}>
                  <h3>{sub.subTitle}</h3>
                  {sub.subContent && <p dangerouslySetInnerHTML={{ __html: sub.subContent }} />}
                  {sub.subItems && (
                    <ol>
                      {sub.subItems.map((item, i) => (
                        <li key={i}>{item}</li>
                      ))}
                    </ol>
                  )}
                  {sub.examples && (
                    <ol>
                      {sub.examples.map((example, i) => (
                        <li key={i}>{example}</li>
                      ))}
                    </ol>
                  )}
                  {sub.additionalContent && <p>{sub.additionalContent}</p>}
                </div>
              ))}
          </div>
        ))}
        <h2>8. Contact Us</h2>
        <p>
          If you have any questions about this Cookie Policy or our use of
          cookies, please contact us at:
          <br />
          <b>Ecohodo Technologies Pvt Ltd</b>
          <br />
          Email: <a className="emailLink" href="mailto:contact@ecohodo.ai">contact@ecohodo.ai</a>
          <br />
          Website: <a className="websiteLink" href="https://ecohodo.ai/" target="_blank">www.ecohodo.ai</a>
        </p>
      </div>
  
    </div>
  );
}
