import React from 'react'
import './Header.css'
import CallIcon from '@mui/icons-material/Call';
import ContactUsModal from "./ContactUsModal";

export default function Header({ contactUsOpen, setContactUsOpen, contactOpen, contactClose, showToast }) {
    const redirectSignUp = () => {
        window.location.href = 'https://auth.ecohodo.ai/auth/signup';
    };

    return (

        <div id='Header'>
            <div className='header-content'>
                <div className='navbar-logo'>
                    <a href='/'><img src={require('../../assets/images/logo.png')} alt="ecohodo-logo" /></a>
                </div>
                {/* <div className='header-links'>
                <a href='#OurProducts'>Our Products</a>
                <a href='#OurFeatures'>Our Features</a>
                <a href='#OurPartners'>Our Partners</a>
                <a href='#OurProjects'>Our Projects</a>
                <a href='#'>About Us</a>
                <a href='#'>Connect With US</a>
            </div> */}
                <div class='btns'>
                    <button class='btn1' onClick={redirectSignUp}>Sign Up</button>
                    <button class='btn2' onClick={contactOpen}><CallIcon sx={{ fontSize: 14, mr: 1 }} />Contact Us</button>
                    <ContactUsModal
                        contactUsOpen={contactUsOpen}
                        setContactUsOpen={setContactUsOpen}
                        contactOpen={contactOpen}
                        contactClose={contactClose}
                        showToast={showToast}
                    />
                </div>
            </div>
        </div>
    )
}
