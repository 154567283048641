import React from 'react'
import './Hero.css'

export default function Hero() {
  return (
    <div> 
        <div class='hero-section'>
            <div class='hero-content'>
                <p class='heading'>Empowering the <span class='highlight'>Carbon Market Ecosystem </span>with Digital Tools for True Transparency</p>
                <p class='sub-heading'>We offer a Carbon credits Xchange, GHG Emission Reporting and dMRV (digital monitoring, reporting, and verification) tool – each designed to enhance transparency and accuracy in carbon market. To bridge industry and environment for all.</p>
                <div class='btns'>
                    <a class='button' href='#OurProducts'>Explore Our Products</a>
                    {/* <button class='btn2'>About Us</button> */}
                </div>
            </div>    
        </div>
    </div>
  )
}
