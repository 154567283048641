import * as React from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import ContactUsForm from './ContactUsForm'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function ContactUsModal(props) {

  return (
    <div>
      <Modal
        open={props.contactUsOpen}
        onClose={props.contactClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ContactUsForm 
            showToast={props.showToast}
            close ={props.setContactUsOpen}

        />
      </Modal>
    </div>
  );
}
