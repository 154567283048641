import React from "react";
import "./Features.css";
import FeaturesCarousel from "./FeaturesCarousel";

export default function Features() {
  return (
    <div id="OurFeatures">
      <div class="content">
        <div class="heading-para">
          <h2>Our Approach To Carbon Market Challenges</h2>
          <p style={{ fontSize: "20px" }}>
            Ecohodo ensures transparency regulatory compliance, and efficiency
            in carbon market. We tackle greenwashing, simplify complex
            regulations, and provide end-to-end support for carbon projects,
            leveraging technology and expert consulting to drive sustainable
            outcomes.
          </p>
        </div>
        <div className="feature-boxes">
          <div class="box">
            <img
              src={require("../../assets/images/feature-img1.png")}
              alt="feature"
            />
            <h4>Lack of Transparency</h4>
            <p>
              The global carbon market is plagued by greenwashing and inadequate
              transparency. Ecohodo addresses this by ensuring bottom-line
              transparency in all our services, from carbon offset projects to
              emissions reporting and carbon credit sales.
            </p>
          </div>

          <div class="box">
            <img
              src={require("../../assets/images/feature-img2.png")}
              alt="feature"
            />
            <h4>Complex Regulatory Compliance</h4>
            <p>
              Navigating the ever-changing landscape of international carbon
              regulations like CBAM, EUDR, and CCTS can be challenging. Ecohodo
              provides comprehensive expertise to help you stay compliant,
              ensuring your decarbonization strategy aligns with current
              regulations.
            </p>
          </div>
          <div class="box">
            <img
              src={require("../../assets/images/feature-img3.png")}
              alt="feature"
            />
            <h4>Inefficient Carbon Project</h4>
            <p>
              Developing and managing carbon offset projects can be
              time-consuming and complex. Ecohodo simplifies this with
              end-to-end support, from feasibility studies to carbon credit
              issuance, ensuring your projects are efficient and impactful.
            </p>
          </div>
          <div class="box">
            <img
              src={require("../../assets/images/feature-img4.png")}
              alt="feature"
            />
            <h4>Technological Integration</h4>
            <p>
              Our consulting services extend to integrating cutting-edge
              technology into your sustainability initiatives. We help you
              leverage our Carbon Credit Trading App, Reporting SaaS, and dMRV
              tools to enhance transparency and efficiency in your carbon
              management efforts.
            </p>
          </div>
          <div class="box">
            <img
              src={require("../../assets/images/feature-img5.png")}
              alt="feature"
            />
            <h4>Decarbonization Consulting</h4>
            <p>
              We provide specialized consulting in decarbonization, focusing on
              emissions reduction strategies, green supply chain management, and
              comprehensive guidance to ensure compliance with the latest
              regulatory and sustainability standards
            </p>
          </div>
          <div class="box">
            <img
              src={require("../../assets/images/feature-img6.png")}
              alt="feature"
            />
            <h4>Project Development</h4>
            <p>
              Ecohodo specializes in developing carbon offset projects, ensuring
              they meet the highest standards. Our services include project
              registration, monitoring, and end to end project management for
              carbon credit generation.
            </p>
          </div>
        </div>
        <div className="carousel-div">
          <FeaturesCarousel />
        </div>
      </div>
    </div>
  );
}
