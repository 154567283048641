import React from "react";
import Carousel from "react-material-ui-carousel";
import { Paper } from "@mui/material";
import FeatureOne from "../../assets/images/feature-img1.png";
import FeatureTwo from "../../assets/images/feature-img2.png";
import FeatureThree from "../../assets/images/feature-img3.png";
import FeatureFour from "../../assets/images/feature-img4.png";
import FeatureFive from "../../assets/images/feature-img5.png";
import FeatureSix from "../../assets/images/feature-img6.png";

const featuresBox = [
  {
    img: FeatureOne,
    heading: "Lack of Transparency",
    para: "The global carbon market is plagued by greenwashing and inadequate transparency. Ecohodo addresses this by ensuring bottom-line transparency in all our services, from carbon offset projects to emissions reporting and carbon credit sales.",
  },
  {
    img: FeatureTwo,
    heading: "Complex Regulatory Compliance",
    para: "Navigating the ever-changing landscape of international carbon regulations like CBAM, EUDR, and CCTS can be challenging. Ecohodo provides comprehensive expertise to help you stay compliant, ensuring your decarbonization strategy aligns with current regulations.",
  },
  {
    img: FeatureThree,
    heading: "Inefficient Carbon Project",
    para: "Developing and managing carbon offset projects can be time-consuming and complex. Ecohodo simplifies this with end-to-end support, from feasibility studies to carbon credit issuance, ensuring your projects are efficient and impactful.",
  },
  {
    img: FeatureFour,
    heading: "Technological Integration",
    para: "Our consulting services extend to integrating cutting-edge technology into your sustainability initiatives. We help you leverage our Carbon Credit Trading App, Reporting SaaS, and dMRV tools to enhance transparency and efficiency in your carbon management efforts.",
  },
  {
    img: FeatureFive,
    heading: "Decarbonization Consulting",
    para: "We provide specialized consulting in decarbonization, focusing on emissions reduction strategies, green supply chain management, and comprehensive guidance to ensure compliance with the latest regulatory and sustainability standards",
  },
  {
    img: FeatureSix,
    heading: "Project Development",
    para: "Ecohodo specializes in developing carbon offset projects, ensuring they meet the highest standards. Our services include project registration, monitoring, and end to end project management for  carbon credit generation. ",
  },
];

function FeaturesCarousel() {
  return (
    <Carousel
      autoPlay={true}
    >
      {featuresBox.map((itemGroup, index) => (
        <Paper
          key={index}
          elevation={2}
          sx={{ backgroundColor: "transparent" }}
        >

          <div class="box">
            <img src={itemGroup["img"]} alt={itemGroup["heading"]} />
            <h4>{itemGroup["heading"]}</h4>
            <p>{itemGroup["para"]}</p>
          </div>
        </Paper>
      ))}
    </Carousel>
  );
}

export default FeaturesCarousel;
