/* eslint-disable react/jsx-no-target-blank */
import React from "react";

import privacyPolicy from "./privacyPolicy.json";
import "./policy.css";


export default function CookiePolicy() {
  return (
    <div className="main-style">
      {/* <Header /> */}
      <div className="policyTitle">
        <h1>
          <span className="highlight">Privacy Policy</span> for Ecohodo
          Technologies Pvt. Ltd.
        </h1>
      </div>
      <div className="policyContent">
        <p>
          Welcome to the Ecohodo platform, provided by Ecohodo Technologies Pvt
          Ltd (“Ecohodo,” “we,” or “us”). This Privacy Policy outlines how we
          collect, use, and protect your personal information across our Carbon
          Credits Marketplace, Consulting Services, and upcoming SaaS products
          (GHGsync and TrueMRV).<br /> By using the Ecohodo platform and related
          services, you consent to the data practices described in this policy.
          If you do not agree with this policy, please refrain from using
          Ecohodo services, including the Ecohodo Website and SaaS products. If
          you have any questions or concerns about this policy, contact us at:
          privacy@ecohodo.ai.
        </p>
        {privacyPolicy.map((section) => (
          <div key={section.id}>
            <h2>
              {section.id}. {section.title}
            </h2>
            <p dangerouslySetInnerHTML={{ __html: section.content }} />
            {section.subContent &&
              section.subContent.map((sub, index) => (
                <div key={index}>
                  <h3>{sub.subTitle}</h3>
                  {sub.subContent && (
                    <p dangerouslySetInnerHTML={{ __html: sub.subContent }} />
                  )}
                  {sub.subItems && (
                    <ol>
                      {sub.subItems.map((item, i) => (
                        <li key={i}>{item}</li>
                      ))}
                    </ol>
                  )}
                  {sub.examples && (
                    <ol>
                      {sub.examples.map((example, i) => (
                        <li key={i}>{example}</li>
                      ))}
                    </ol>
                  )}
                  {sub.additionalContent && <p dangerouslySetInnerHTML={{ __html: sub.additionalContent }} />}
                </div>
              ))}
          </div>
        ))}
        <h2>11. Contact Us</h2>
        <p>
          If you have any questions about this Cookie Policy or our use of
          cookies, please contact us at:
          <br />
          <b>Ecohodo Technologies Pvt Ltd</b>
          <br />
          Email:{" "}
          <a className="emailLink" href="mailto:contact@ecohodo.ai">
            contact@ecohodo.ai
          </a>
          <br />
          Website:{" "}
          <a className="websiteLink" href="https://ecohodo.ai/" target="_blank">
            www.ecohodo.ai
          </a>
        </p>
      </div>
      {/* <Footer /> */}
    </div>
  );
}
