import React from 'react'
import Newsletter from './Newsletter'
import Copyright from './Copyright'

export default function Footer() {
  return (
    <div>
        {/* <Newsletter /> */}
        <Copyright />
    </div>
  )
}
