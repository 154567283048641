import React from "react";
import PartnerCarousel from "./PartnerCarousel"; 
import './OurPartners.css'

function OurPartners() {
  return (
    <div className="ourPartners-content" id="OurPartners">
      <p>
        Collaborating for a Greener Future:{" "}
        <span class="highlight">Our Impact Partners</span>
      </p>
      <PartnerCarousel />
    </div>
  );
}

export default OurPartners;