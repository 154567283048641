import React from "react";
import CircleIcon from "@mui/icons-material/Circle";
// import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import "./Services.css";
import MessageIcon from "@mui/icons-material/Message";
import ConsultationModal from "./ConsultationModal";
import ContactUsModal from "./ContactUsModal";
// `import CallIcon from '@mui/icons-material/Call';`


export default function Services({ contactUsOpen, setContactUsOpen, contactOpen, contactClose }) {
  const [demoBtn, setDemoBtn] = React.useState(false);
  const [consultModel, setConsultModel] = React.useState(false);
  const [consult2Model, setConsult2Model] = React.useState(false);

  const redirectLogin = () => {
    window.location.href = 'https://auth.ecohodo.ai/auth/login';
  };

  const redirectSignUp = () => {
    window.location.href = 'https://auth.ecohodo.ai/auth/signup';
  };

  return (
    <div class="service-section" id="OurProducts">
      <div class="service-content">

        {/* carbon credit trading platform */}
        <div class="carbon-dmrv">
          <div class="content">
            <span
              class="status"
              style={{
                color: "#269E40",
                backgroundColor: "rgba(135, 189, 64, 0.2)",
              }}
            >
              <CircleIcon sx={{ fontSize: 12, color: "#269E40", mr: 1 }} /> JUST
              LAUNCHED
            </span>
            <h3 class="service-name">Ecohodo Carbon MarketPlace</h3>
            <p class="sub-heading">
              A free-to-register platform offering rated carbon offset projects,
              verified through thorough 19-point due diligence. Seamlessly trade
              with transparency, ensuring trust between sellers and buyers of
              carbon credits. Empower your business to participate in carbon
              offsetting with ease and relability.
            </p>
            <div class="btns new-btns">
              {/* <button class="btn1" onClick={() => setDemoBtn(true)}>Book A Demo</button> */}
              <button class="btn1" onClick={redirectSignUp}>Sign Up</button>
              <ConsultationModal
                title={false}
                open={demoBtn}
                handleClose={() => setDemoBtn(false)}
              />
              <button class="btn2 newBtns2" onClick={redirectLogin}>

                Explore
              </button>
              <ContactUsModal
                contactUsOpen={contactUsOpen}
                setContactUsOpen={setContactUsOpen}
                contactOpen={contactOpen}
                contactClose={contactClose}
              />
            </div>
          </div>
          <div class="service-img">
            <img
              src={require("../../assets/images/service-img1.png")}
              alt="carbon_credit"
            />
          </div>
        </div>

        {/* GHG Emission Reporting SAAS */}
        <div class="carbon-dmrv flex-direction-reverse">
          <div class="service-img">
            <img
              src={require("../../assets/images/service-img2.png")}
              alt="ghg-emission"
            />
          </div>
          <div class="content">
            <span
              class="status"
              style={{ color: "#FFB800", backgroundColor: "#FFF8E6" }}
            >
              <CircleIcon sx={{ fontSize: 12, color: "#FFB800", mr: 1 }} />
              TO BE OUT NEXT
            </span>
            <h3 class="service-name">
              GHGsync (Automated GHG Emissions Reporting Tool)
            </h3>
            <p class="sub-heading">
              Curious about how GHG emissions management could be effortless?
              Discover GHGsync, the tool that simplifies every step—from data
              collection to reporting—with unmatched accuracy across all
              sectors. Make GHG reporting easy and precise. Get started today!
            </p>
            <div class="btns new-btns">
              {/* <button class="btn1">Sign in our platform</button> */}
              <button class="btn2 newBtns2" onClick={() => setConsultModel(true)}>
                <MessageIcon sx={{ fontSize: 14, mr: 1 }} />
                Book Consultation
              </button>
              <ConsultationModal
                title={true}
                open={consultModel}
                handleClose={() => setConsultModel(false)}
              />
            </div>
          </div>
        </div>

        {/* dMRV Tech */}
        <div class="carbon-dmrv">
          <div class="content">
            <span
              class="status"
              style={{ color: "#2158b1", backgroundColor: "#99baeb" }}
            >
              <CircleIcon sx={{ fontSize: 12, color: "#2158b1", mr: 1 }} />
              COMING SOON....
            </span>
            <h3 class="service-name">TrueMRV (AI-enabled dMRV tool)</h3>
            <p class="sub-heading">
              TrueMRV is Ecohodo’s advanced tool for automated digital
              Monitoring, Reporting, and Verification (dMRV). Powered by AI and
              ML algorithms, TrueMRV enhances every aspect of carbon offset
              project. Designed to be universally adaptable, TrueMRV simplifies
              carbon market compliance while providing unmatched transparency
              and accuracy.
            </p>
            <div class="btns new-btns">
              {/* <button class="btn1">Subscribe Our Newsletter</button> */}
              <button class="btn2 newBtns2" onClick={() => setConsult2Model(true)}>
                <MessageIcon sx={{ fontSize: 14, mr: 1 }} />
                Book Consultation
              </button>
              <ConsultationModal
                title={true}
                open={consult2Model}
                handleClose={() => setConsult2Model(false)}
              />
            </div>
          </div>
          <div class="service-img">
            <img
              src={require("../../assets/images/service-img3.png")}
              alt="dmrv tech"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
