import React from 'react'
import './BlogBox.css'

export default function BlogBox() {
    let blogArr = [
        {
          img: require("../../../assets/images/blog-img1.png"),
          title: "Analysis: UK could approve 13 new oil and gas projects despite North Sea pledge",
          alt: "landscape",
          status: "Weather",
          pp: require("../../../assets/images/blog-img2.png"),
          blogerName: "Tatiana Culhane",
          blogDate: "August 20, 2024"
          
        },
        {
            img: require("../../../assets/images/blog-img1.png"),
            title: "Analysis: UK could approve 13 new oil and gas projects despite North Sea pledge",
            alt: "landscape",
            status: "Weather",
            pp: require("../../../assets/images/blog-img2.png"),
            blogerName: "Tatiana Culhane",
            blogDate: "August 20, 2024"
            
        },
        {
            img: require("../../../assets/images/blog-img1.png"),
            title: "Analysis: UK could approve 13 new oil and gas projects despite North Sea pledge",
            alt: "landscape",
            status: "Weather",
            pp: require("../../../assets/images/blog-img2.png"),
            blogerName: "Tatiana Culhane",
            blogDate: "August 20, 2024"
            
        },
        {
            img: require("../../../assets/images/blog-img1.png"),
            title: "Analysis: UK could approve 13 new oil and gas projects despite North Sea pledge",
            alt: "landscape",
            status: "Weather",
            pp: require("../../../assets/images/blog-img2.png"),
            blogerName: "Tatiana Culhane",
            blogDate: "August 20, 2024"
            
        },
        {
            img: require("../../../assets/images/blog-img1.png"),
            title: "Analysis: UK could approve 13 new oil and gas projects despite North Sea pledge",
            alt: "landscape",
            status: "Weather",
            pp: require("../../../assets/images/blog-img2.png"),
            blogerName: "Tatiana Culhane",
            blogDate: "August 20, 2024"
            
        },
        {
            img: require("../../../assets/images/blog-img1.png"),
            title: "Analysis: UK could approve 13 new oil and gas projects despite North Sea pledge",
            alt: "landscape",
            status: "Weather",
            pp: require("../../../assets/images/blog-img2.png"),
            blogerName: "Tatiana Culhane",
            blogDate: "August 20, 2024"
            
        },
        {
            img: require("../../../assets/images/blog-img1.png"),
            title: "Analysis: UK could approve 13 new oil and gas projects despite North Sea pledge",
            alt: "landscape",
            status: "Weather",
            pp: require("../../../assets/images/blog-img2.png"),
            blogerName: "Tatiana Culhane",
            blogDate: "August 20, 2024"
            
        },
        {
            img: require("../../../assets/images/blog-img1.png"),
            title: "Analysis: UK could approve 13 new oil and gas projects despite North Sea pledge",
            alt: "landscape",
            status: "Weather",
            pp: require("../../../assets/images/blog-img2.png"),
            blogerName: "Tatiana Culhane",
            blogDate: "August 20, 2024"
            
        }
        
        
      ];
  return (
    <div className='blog'>
        <div className='blogContainer'>
            <div class="boxContainer">
                {blogArr.map((item, indx) => {
                    return (
                        <div className='blogContent'>
                            <img src={item.img} alt={item.alt} />
                            <span
                                class="main"
                                style={{
                                color: "#269E40",
                                backgroundColor: "rgba(233, 245, 236, 0.1)",
                                }}
                            >
                                {item.status}
                            </span>
                            <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                                {item.title}
                            </p>
                            <div class="name-date">
                                    <img
                                    src={item.pp}
                                    alt="blogger-profile-pic"
                                    />
                                    <p>{item.blogerName}</p>
                                    <p>{item.blogDate}</p>
                            </div>
                        </div>
                        

                    );
                })}
                    
            </div>

        </div>

    </div>
  )
}
