import React from "react";
import termOfService from "./termOfService.json";
import "./policy.css";


export default function TermOfService() {
  return (
    <div className="main-style">
      {/* <Header /> */}
      <div className="policyTitle">
        <h1>
          Ecohodo Technologies Pvt. Ltd.{" "}
          <span className="highlight">Terms of Service</span>
        </h1>
      </div>
      <div className="policyContent">
        <p>
          Welcome to the Ecohodo platform, provided by <b>Ecohodo Technologies Pvt
          Ltd</b> (“Ecohodo,” “we,” “us,” or “our”). These terms of service
          (“Terms”) govern your relationship with us and set forth the
          conditions under which you may access and use the Ecohodo Carbon
          Credits Marketplace, Consulting Services, and SaaS products (GHGsync
          and TrueMRV), collectively referred to as the “Services”. By accessing
          or using any of our Services, you agree to these Terms, which form a
          legally binding agreement between you and Ecohodo. If you do not agree
          with these Terms, you should discontinue using our Services.
        </p>
        {termOfService.map((section) => (
          <div key={section.id}>
            <h2>
              {section.id}. {section.title}
            </h2>
            <p dangerouslySetInnerHTML={{ __html: section.content }} />
            {section.subContent &&
              section.subContent.map((sub, index) => (
                <div key={index}>
                  <h3>{sub.subTitle}</h3>
                  {sub.subContent && (
                    <p dangerouslySetInnerHTML={{ __html: sub.subContent }} />
                  )}
                  {sub.subItems && (
                    <ol>
                      {sub.subItems.map((item, i) => (
                        <li key={i}>{item}</li>
                      ))}
                    </ol>
                  )}
                  {sub.examples && (
                    <ol>
                      {sub.examples.map((example, i) => (
                        <li key={i}>{example}</li>
                      ))}
                    </ol>
                  )}
                  {sub.additionalContent && <p>{sub.additionalContent}</p>}
                </div>
              ))}
          </div>
        ))}
        
      </div>
      {/* <Footer /> */}
    </div>
  );
}
