// import logo from './logo.svg';
import "./App.css";
import BlogListing from "./Home/components/Blogs/BlogListing";
import CookiePolicy from "./Home/components/Policies/CookiePolicy";
import TermOfService from "./Home/components/Policies/TermOfService";
import PrivacyPolicy from "./Home/components/Policies/PrivacyPolicy";
import Home from "./Home/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import React from "react";
import Header from "./Home/components/Header";
import Footer from "./Home/components/Footer";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [contactUsOpen, setContactUsOpen] = React.useState(false);
  const contactOpen = () => setContactUsOpen(true);
  const contactClose = () => setContactUsOpen(false);

  function showToast() {
    toast.success("Form submitted successfully!");
  }
  const propList = {
    contactUsOpen,
    setContactUsOpen,
    contactOpen,
    contactClose,
    showToast,
  };
  return (
    <div className="App">
      <Header
        contactUsOpen={contactUsOpen}
        setContactUsOpen={setContactUsOpen}
        contactOpen={contactOpen}
        contactClose={contactClose}
        showToast={showToast}
      />
      <Router>
        <Routes>
          {/* Define the route paths */}
          <Route path="/" element={<Home {...propList} />} />
          <Route path="/bloglisting" element={<BlogListing {...propList} />} />
          {/* <Route path="/blog-details" element={<BlogDetails />} /> */}
          <Route
            path="/termsOfService"
            element={<TermOfService {...propList} />}
          />
          <Route
            path="/cookiePolicy"
            element={<CookiePolicy {...propList} />}
          />
          <Route
            path="/privacyPolicy"
            element={<PrivacyPolicy {...propList} />}
          />
        </Routes>
      </Router>
      <Footer />
      <ToastContainer />
      {/* <Home />
      <BlogListing /> */}
    </div>
  );
}

export default App;
