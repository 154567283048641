/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { TextField, Button, Grid, Box, Typography } from "@mui/material";
import consultation from "../../assets/images/consultationImg.png";
import "react-datepicker/dist/react-datepicker.css";
import { POST } from "./Request";
import { toast } from "react-toastify";



function ContactUsForm(props) {
  const defaultValues = {
    name: "",
    email: "",
    phone: "",
    companyName: "",
    message: "",
  }
  const {
    control,
    handleSubmit,
    reset,
    // eslint-disable-next-line no-unused-vars
    setValue,
    formState: { errors },
  } = useForm({...defaultValues});

  const onSubmit = async (data) => {

    const payload = {
      userName: data["name"],
      userEmail: data["email"],
      userPhone: data["phone"],
      companyName: data["companyName"],
      message: data["message"],
      type: "Contact",
    };


      const res = await POST("consultant-submission", payload);

      if (!res.status) {
        toast.error("Something went wrong!");
        return; 
      }

      toast.success("Form submitted successfully!");
      reset(defaultValues);  
      props.close(false);

  };

  const textColor = {
    color: "white",
  };

  const textFieldStyles = {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white", // Default border color
      },
      "&:hover fieldset": {
        borderColor: "white", // Border color on hover
      },
      "&.Mui-focused fieldset": {
        borderColor: "white", // Border color when focused
      },
    },
    "& .MuiInputLabel-root": {
      color: "white", // Label color
    },
    "& .MuiInputBase-input": {
      color: "white", // Input text color
    },
  };

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      sx={{
        backgroundColor: "rgba(78, 188, 103, 1)",
        maxWidth: "756px",
        margin: "30px auto",
        borderRadius: "45px",
        display: "flex",
        justifyContent: "space-between",
        padding: "0px !important",
        width: {
          xs: "80%",
          md: "100%",
        },
      }}
    >
      {/* Image Section */}
      <Grid
        item
        xs={0}
        md={5}
        sx={{
          padding: "0px !important",
          width: "30%",
          display: {
            xs: "none",
            md: "block",
          },
        }}
      >
        <img
          src={consultation}
          alt="Your Image Here"
          style={{
            width: "80%",
            margin: "auto",
            justifyContent: "start",
          }}
        />
      </Grid>

      {/* Form Section */}
      <Grid
        item
        xs={12}
        md={7}
        sx={{
          width: "70%",
          backgroundColor: "grey",
          borderTopRightRadius: "8%",
          borderBottomRightRadius: "8%",
          borderTopLeftRadius: {
            xs: "8%",
            md: "0",
          },
          borderBottomLeftRadius: {
            xs: "8%",
            md: "0",
          },
        }}
      >
        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            padding: "30px",
            textAlign: "start",
            width: "80%",
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Typography
            variant="h4"
            component="div"
            gutterBottom
            sx={{ ...textColor, fontSize: "28px", fontWeight: "bold" }}
          >
            Contact Us
          </Typography>

          <Typography
            variant="h5"
            component="div"
            gutterBottom
            sx={{ ...textColor, fontSize: "20px" }}
          >
            Fill your details below to contact us
          </Typography>

          <Controller
            name="name"
            control={control}
            rules={{ required: "Name is required" }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Name"
                variant="outlined"
                error={!!errors.name}
                helperText={errors.name ? errors.name.message : ""}
                sx={textFieldStyles}
              />
            )}
          />

          <Controller
            name="email"
            control={control}
            rules={{
              required: "Email is required",
              pattern: {
                value: /^\S+@\S+$/i,
                message: "Invalid email address",
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Email Address"
                variant="outlined"
                error={!!errors.email}
                helperText={errors.email ? errors.email.message : ""}
                sx={textFieldStyles}
              />
            )}
          />

          <Controller
            name="phone"
            control={control}
            rules={{
              required: "Phone number is required",
              pattern: { value: /^\d{10}$/, message: "Invalid phone number" },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Phone Number"
                variant="outlined"
                error={!!errors.phone}
                helperText={errors.phone ? errors.phone.message : ""}
                sx={textFieldStyles}
              />
            )}
          />

          <Controller
            name="companyName"
            control={control}
            rules={{ required: "Company Name is required" }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Company Name"
                variant="outlined"
                error={!!errors.name}
                helperText={errors.name ? errors.name.message : ""}
                sx={textFieldStyles}
              />
            )}
          />

          <Controller
            name="message"
            control={control}
            defaultValue=""
            rules={{
              required: "Message is required",
              minLength: {
                value: 10,
                message: "Message should be at least 10 characters long",
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Message"
                variant="outlined"
                fullWidth
                multiline
                minRows={8}
                maxRows={30}
                placeholder="Write from here...."
                sx={{
                  ...textFieldStyles,
                  minHeight: "100px",
                  "& > div": {
                    height: "inherit",
                  },
                }}
                rows={4}
                error={!!errors.message}
                helperText={errors.message ? errors.message.message : ""}
              />
            )}
          />

          <Button
            type="submit"
            variant="contained"
            sx={{
              backgroundColor: "rgba(38, 158, 64, 1)",
              color: "white",
              textTransform: "none",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "rgba(38, 158, 64, 0.6)",
              },
            }}
          >
            submit
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}

export default ContactUsForm;
